<template>
  <div class="card">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-12">
          <h4>
            Rx Request ID: {{ prescriptionDetails.id }} - Prescribe Medicines
          </h4>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <!-- Search -->
          <div class="search-box mt-3">
            <div class="form-group search-info">
              <vue-select class="form-control" v-model="searchMedicine" :options="medicineOptions" searchable
                clear-on-close close-on-select clear-on-select :loading="loading" @search:input="startSearching"
                search-placeholder="Search Medicines"></vue-select>
              <span class="form-text mb-4">Ex: Glipizide, Lipitor</span>
            </div>
            <button @click="addMedicineToList" class="btn btn-primary search-btn mt-0">
              <i class="fas fa-plus"></i> <span>Add</span>
            </button>
          </div>
          <!-- /Search -->
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card meds-list">
            <div class="card-header">
              <h5>Medicine List</h5>
            </div>
            <ul class="list-group list-group-flush" style="height: 400px; overflow: auto">
              <li class="list-group-item mt-2" style="font-size: 14px" v-for="(medicine, i) in selectedMedicines"
                :key="i">
                <div class="row">
                  <div class="col-10">
                    {{
                      medicine.medicine_name
                        ? medicine.medicine_name
                        : medicine.name
                    }}
                  </div>
                  <div class="col-2">
                    <button @click="removeMedicineFromList(medicine)" class="btn btn-sm btn-light mr-2 btn-block"
                      title="Remove Medicine">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>


                <div class="row mt-2">
                  <div class="col-12">
                    <!-- For Web (Table View) -->
                    <div class="table-responsive d-none d-lg-block">
                      <!-- DIRECTIONS TO USE AND QUANTITY (Web Version) -->
                      <table class="table  table-hover table-center webViewTable">
                        <thead>
                          <tr>
                            <th>Directions</th>
                            <th>Notes</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                            <th>Refills</th>
                            <th>Days Supply</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="form-group">
                                <textarea class="form-control" v-model="medicine.directions"
                                  placeholder="Directions of use"></textarea>
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <textarea class="form-control" v-model="medicine.pharmacy_note"
                                  placeholder="Notes to Pharmacy"></textarea>
                              </div>
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model="medicine.quantity" min="1" max="5000"
                                step="any" @change="
                                  quantityCorrection(
                                    selectedMedicines,
                                    medicine.quantity,
                                    i
                                  )
                                  " />
                            </td>
                            <td>
                              <select class="form-control select" tabindex="-1" aria-hidden="true"
                                v-model="medicine.unit_id" required>
                                <option v-for="(unit, i) in units" :key="i" :value="unit.StandardDispenseUnitTypeID">
                                  {{ unit.Plural }} ({{ unit.Abbreviation }})
                                </option>
                              </select>
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model="medicine.refills" min="0" max="99" />
                            </td>
                            <td>
                              <input type="number" class="form-control" v-model="medicine.supply_days" min="0"
                                max="999" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-block d-lg-none">
                      <!-- Card Layout for Mobile -->
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="form-group">
                            <label for="directions">Directions of Use</label>
                            <textarea class="form-control" v-model="medicine.directions"
                                  placeholder="Directions of use"></textarea>
                          </div>
                          <div class="form-group">
                            <label for="directions">Notes</label>
                            <textarea class="form-control" v-model="medicine.pharmacy_note"
                                  placeholder="Notes to Pharmacy"></textarea>
                          </div>
                          <div class="form-group">
                            <label for="directions">Quantity</label>
                            <input type="number" class="form-control" v-model="medicine.quantity" min="1" max="5000"
                                step="any" @change="
                                  quantityCorrection(
                                    selectedMedicines,
                                    medicine.quantity,
                                    i
                                  )
                                  " />
                          </div>
                          <div class="form-group">
                            <label for="directions">Unit</label>
                            <select class="form-control select" tabindex="-1" aria-hidden="true"
                                v-model="medicine.unit_id" required>
                                <option v-for="(unit, i) in units" :key="i" :value="unit.StandardDispenseUnitTypeID">
                                  {{ unit.Plural }} ({{ unit.Abbreviation }})
                                </option>
                              </select>
                          </div>
                          <div class="form-group">
                            <label for="directions">Refills</label>
                            <input type="number" class="form-control" v-model="medicine.refills" min="0" max="99" />
                          </div>
                          <div class="form-group">
                            <label for="directions">Days Supply</label>
                            <input type="number" class="form-control" v-model="medicine.supply_days" min="0"
                            max="999" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <button @click="saveMedicines" class="btn bg-success-light mr-2 btn-block"
            v-show="selectedMedicines.length > 0">
            <i class="fas fa-check"></i> Save Prescription
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import mixin from "../../mixins/common";

export default {
  props: {
    prescriptionDetails: Object,
  },
  mixins: [mixin],
  data() {
    const meds =
      this.prescriptionDetails && this.prescriptionDetails.medicines
        ? this.prescriptionDetails.medicines
        : [];

    return {
      searchMedicine: "",
      medicineOptions: [],
      selectedMedicines: meds,
      loading: false,
      units: [],
    };
  },
  async mounted() {
    const r = await this.getDispenseUnits();
    this.units = r
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((e) => e.IsActive === true);
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    saveMedicines() {
      this.$emit("submit", JSON.stringify(this.selectedMedicines));
    },

    selectMedicines() {
      if (this.selectedMedicines.length === 0) {
        this.showAlert({
          message: "Please select medicines for prescription",
          type: "danger",
          time: 4000,
        });
        return;
      }
      this.$emit("select", JSON.stringify(this.selectedMedicines));
    },

    removeMedicineFromList(medicine) {
      this.selectedMedicines = this.selectedMedicines.filter(
        (a) => a.name != medicine.name
      );
    },

    addMedicineToList() {
      if (this.searchMedicine === "") return;

      const med = this.masterMedicineOptions.find(
        (medicine) => medicine.name === this.searchMedicine
      );

      if (med)
        this.selectedMedicines.push({
          medicine_name: med.name,
          ...med,
          quantity: 0,
          refills: 0,
          supply_days: 0,
          directions: "",
        });

      this.searchMedicine = {};
      this.medicineOptions = [];
      this.masterMedicineOptions = [];
    },

    async startSearching(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;
          const res = await this.searchMedicineByName(input.target.value);

          this.masterMedicineOptions = res;
          if (this.masterMedicineOptions.length > 0) {
            this.medicineOptions = this.masterMedicineOptions.map(
              (a) => a.name
            );
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.showAlert({
          message: e,
          type: "danger",
          time: 4000,
        });
      }
    },

    quantityCorrection(selectedMedicines, quantity, medIndex) {
      let selectedMedicine = selectedMedicines[medIndex];
      // If all zeros in decimal, change the quantity to whole number
      if (quantity % 1 === 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = Math.round(quantity);
      }

      // Populate zero in first place if input is .123
      if (String(quantity).startsWith("0.")) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity = quantity;
      }

      if (String(quantity).length > 10 && String(quantity).indexOf(".") >= 0) {
        selectedMedicine.quantity = 0;
        selectedMedicine.quantity =
          String(quantity).substr(0, String(quantity).indexOf(".")) +
          String(quantity).substr(String(quantity).indexOf("."), 6);
      }

      // if (quantity > 5000) selectedMedicine.quantity = 5000;
    },
  },
};
</script>

<style scoped>
.cont-btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 90%;
}

.meds-list {
  position: relative;
  height: 350px;
}

.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 92%;
  width: 92%;
}

.search-info .form-control {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .search-info .form-control {
    width: 110%;
  }
}

/* Responsive design for smaller screens */
@media (max-width: 767px) {
  .table-responsive {
    display: block;
    overflow-x: auto;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    width: 100%;
  }

  .table th,
  .table td {
    padding: 10px;
    text-align: left;
    font-size: 14px;
  }

  .table th {
    font-size: 16px;
  }

  /* Stack form fields on mobile */
  .table td input,
  .table td select,
  .table td textarea,
  .table td input[type="file"] {
    width: 100%;
    margin-bottom: 10px;
  }

  /* For tablets and small devices */
  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }

  /* Style for the file input specifically */
  .form-control-file {
    display: block;
    /* Ensures it takes the full width */
    width: 100%;
    /* Make file input width 100% */
  }
}
</style>
