<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>
      <breadcrumb></breadcrumb>
      <!-- Page Content -->
      <div class="content mb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickyside">
                <sidebar @openReferModal="openReferModal"></sidebar>
              </div>
            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">
              <!-- Statistics -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card dash-card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 col-lg-4">
                          <div class="dash-widget dct-border-rht">
                            <div class="circle-bar circle-bar1">
                              <div class="circle-graph1" data-percent="75">
                                <img
                                  src="../../assets/img/icon-01.png"
                                  class="img-fluid"
                                  alt="patient"
                                />
                              </div>
                            </div>
                            <div class="dash-widget-info">
                              <h6>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.status.newRequests"
                                  )
                                }}
                              </h6>
                              <h3>{{ pendingPrescriptions.length }}</h3>
                              <!-- <p class="text-muted">Till Today</p> -->
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 col-lg-4">
                          <div class="dash-widget dct-border-rht">
                            <div class="circle-bar circle-bar2">
                              <div class="circle-graph2" data-percent="65">
                                <img
                                  src="../../assets/img/icon-02.png"
                                  class="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div class="dash-widget-info">
                              <h6>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.status.inProgressRequests"
                                  )
                                }}
                              </h6>
                              <h3>{{ acceptedPrescriptions.length }}</h3>
                              <!-- <p class="text-muted">06, Nov 2019</p> -->
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 col-lg-4">
                          <div class="dash-widget">
                            <div class="circle-bar circle-bar3">
                              <div class="circle-graph3" data-percent="50">
                                <img
                                  src="../../assets/img/icon-03.png"
                                  class="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div class="dash-widget-info">
                              <h6>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.status.completedRequests"
                                  )
                                }}
                              </h6>
                              <h3>{{ completedPrescriptions.length }}</h3>
                              <!-- <p class="text-muted">06, Apr 2019</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Tab Menu -->
              <nav class="user-tabs mb-4">
                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      href="#prescription-requests"
                      data-toggle="tab"
                      >{{
                        $t("provider.sideBar.dashboard.newRequest.title")
                      }}
                      ({{ pendingPrescriptions.length }})</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#accepted-prescription"
                      class="nav-link"
                      data-toggle="tab"
                      >{{
                        $t("provider.sideBar.dashboard.inProgress.title")
                      }}
                      ({{ acceptedPrescriptions.length }})</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#completed-prescription"
                      class="nav-link"
                      data-toggle="tab"
                      >{{
                        $t("provider.sideBar.dashboard.completed.title")
                      }}
                      ({{ completedPrescriptions.length }})</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#rejected-prescription"
                      class="nav-link"
                      data-toggle="tab"
                      >{{
                        $t("provider.sideBar.dashboard.cancelled.title")
                      }}
                      ({{ rejectedPrescriptions.length }})</a
                    >
                  </li>
                </ul>
              </nav>
              <!-- /Tab Menu -->

              <!-- Tab Content -->
              <div class="tab-content pt-0">
                <!-- Prescription Requests Tab -->
                <div
                  class="tab-pane fade show active"
                  id="prescription-requests"
                >
                  <div class="card card-table mb-0">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table
                          v-if="pendingPrescriptions.length > 0"
                          class="table table-hover table-center mb-0"
                        >
                          <thead>
                            <tr>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head1"
                                  )
                                }}
                              </th>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head2"
                                  )
                                }}
                              </th>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head3"
                                  )
                                }}
                              </th>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head4"
                                  )
                                }}
                              </th>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head5"
                                  )
                                }}
                              </th>
                              <th>
                                {{
                                  $t(
                                    "provider.sideBar.dashboard.newRequest.table.head6"
                                  )
                                }}
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                prescription, index
                              ) in pendingPrescriptions"
                              :key="index"
                            >
                              <td>
                                <strong> {{ prescription.id }}</strong>
                              </td>
                              <td>
                                <h2 class="table-avatar">
                                  <router-link
                                    to="/provider/patient-profile"
                                    class="avatar avatar-sm mr-2"
                                    ><img
                                      class="avatar-img rounded-circle"
                                      :src="
                                        prescription.patient.profile_image_url
                                      "
                                      alt="User Image"
                                  /></router-link>
                                  <router-link to="/provider/patient-profile"
                                    >{{
                                      formatLongString(
                                        prescription.patient.first_name
                                      )
                                    }}
                                    {{
                                      formatLongString(
                                        prescription.patient.last_name
                                      )
                                    }}
                                    <span
                                      >Request ID: {{ prescription.id }}</span
                                    >
                                  </router-link>
                                </h2>
                              </td>
                              <td>
                                {{
                                  new Date(
                                    prescription.created_at
                                  ).toLocaleString()
                                }}
                              </td>
                              <td>
                                <span v-if="prescription.type === 'refill'">
                                  {{ normalizeString("Refill") }}
                                </span>
                                <span v-else>
                                  {{ normalizeString("Free Text") }}
                                </span>
                              </td>
                              <td>
                                <span v-if="prescription.type === 'refill'">
                                  {{
                                    normalizeString(prescription.pharmacy.city)
                                  }},
                                  {{ prescription.pharmacy.state }}
                                </span>
                                <span v-else>
                                  {{
                                    normalizeString($store.getters.user.city)
                                  }},
                                  {{ $store.getters.user.state }}
                                </span>
                              </td>
                              <td class="text-center">
                                <div v-if="prescription.payment">
                                  <span
                                    :class="
                                      `badge badge-pill ` +
                                      paymentStatusBatch(
                                        prescription.payment.status
                                      )
                                    "
                                  >
                                    {{
                                      paymentStatus(prescription.payment.status)
                                    }}
                                  </span>
                                </div>
                                <div v-else>
                                  <span
                                    :class="
                                      `badge badge-pill ` +
                                      paymentStatusBatch('Pending')
                                    "
                                  >
                                    {{ paymentStatus("Pending") }}
                                  </span>
                                </div>
                              </td>
                              <td class="text-right">
                                <div class="table-action">
                                  <a
                                    class="btn btn-sm bg-info-light mr-2 btn-block"
                                    href="javascript:void();"
                                    v-if="prescription.is_payment_completed"
                                    @click="
                                      $router.push(
                                        `/provider/request/${prescription.id}`
                                      )
                                    "
                                  >
                                    {{
                                      $t(
                                        "provider.sideBar.dashboard.newRequest.table.button1"
                                      )
                                    }}
                                    <i class="fa fa-angle-right"></i>
                                  </a>
                                  <a
                                    v-else
                                    :id="`cancel-${prescription.id}`"
                                    href="#prescription-reject-modal"
                                    data-toggle="modal"
                                    class="btn btn-sm bg-danger-light mr-2 btn-block"
                                    @click="
                                      () => {
                                        selectedPrescription = prescription;
                                      }
                                    "
                                  >
                                    <i class="fas fa-times"></i>
                                    {{
                                      $t(
                                        "provider.sideBar.dashboard.newRequest.table.button2"
                                      )
                                    }}
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-else class="p-5 text-center">
                          <h5>
                            {{
                              $t(
                                "provider.sideBar.dashboard.newRequest.table.noRequests"
                              )
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Prescription Requests Tab -->

                <!--Pending Prescriptions Tab -->
                <div id="accepted-prescription" class="tab-pane fade">
                  <!-- Web View -->
                  <div class="card card-table mb-0 ">
                    <div class="card-body">
                      <table
                        v-if="acceptedPrescriptions.length > 0"
                        class="table table-responsive table-hover table-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head1"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head2"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head3"
                                )
                              }}
                            </th>
                            <th class="text-center">
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head4"
                                )
                              }}
                            </th>
                            <th class="text-center">
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head5"
                                )
                              }}
                            </th>
                            <th class="text-center">
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head6"
                                )
                              }}
                            </th>
                            <th class="text-center">
                              {{
                                $t(
                                  "provider.sideBar.dashboard.inProgress.table.head7"
                                )
                              }}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              prescription, index
                            ) in acceptedPrescriptions"
                            :key="index"
                          >
                            <td>
                              <strong> {{ prescription.id }}</strong>
                            </td>
                            <td>
                              <h2 class="table-avatar">
                                <router-link
                                  to="/provider/patient-profile"
                                  class="avatar avatar-sm mr-2"
                                  ><img
                                    class="avatar-img rounded-circle"
                                    :src="
                                      prescription.patient.profile_image_url
                                    "
                                    alt="User Image"
                                /></router-link>
                                <router-link to="/provider/patient-profile"
                                  >{{
                                    formatLongString(
                                      prescription.patient.first_name
                                    )
                                  }}
                                  {{
                                    formatLongString(
                                      prescription.patient.last_name
                                    )
                                  }}
                                  <span>Request ID: {{ prescription.id }}</span>
                                </router-link>
                              </h2>
                            </td>
                            <td>
                              {{
                                new Date(
                                  prescription.created_at
                                ).toLocaleString()
                              }}
                            </td>
                            <td>
                              <span v-if="prescription.type === 'refill'">
                                {{ normalizeString("Refill") }}
                              </span>
                              <span v-else>
                                {{ normalizeString("Free Text") }}
                              </span>
                            </td>
                            <td class="text-center">
                              ${{ $store.getters.user.prescription_fee }}
                            </td>
                            <td class="text-center">
                              <div v-if="prescription.payment">
                                <span
                                  :class="
                                    `badge badge-pill ` +
                                    paymentStatusBatch(
                                      prescription.payment.status
                                    )
                                  "
                                >
                                  {{
                                    paymentStatus(prescription.payment.status)
                                  }}
                                </span>
                              </div>
                              <div v-else>
                                <span
                                  :class="
                                    `badge badge-pill ` +
                                    paymentStatusBatch('Pending')
                                  "
                                >
                                  {{ paymentStatus("Pending") }}
                                </span>
                              </div>
                            </td>
                            <td class="text-center">
                              <span
                                :class="
                                  `badge badge-pill ` +
                                  pharmacyStatusBatch(prescription.medicines)
                                "
                              >
                                {{ pharmacyStatus(prescription.medicines) }}
                              </span>
                            </td>
                            <td class="text-right">
                              <div class="table-action">
                                <a
                                  class="btn btn-sm bg-info-light mr-2 mb-2 btn-block"
                                  href="javascript:void();"
                                  data-toggle="modal"
                                  @click="
                                    $router.push(
                                      `/provider/request/${prescription.id}`
                                    )
                                  "
                                >
                                  {{
                                    $t(
                                      "provider.sideBar.dashboard.inProgress.table.button"
                                    )
                                  }}
                                  <i class="fa fa-angle-right"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="p-5 text-center">
                        <h5>
                          {{
                            $t(
                              "provider.sideBar.dashboard.inProgress.table.noPendingApprovals"
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <!-- / Web View -->
                </div>
                <!-- Pending Prescriptions Tab -->

                <!--Completed Prescriptions Tab -->
                <div id="completed-prescription" class="tab-pane fade">
                  <div class="card card-table mb-0">
                    <div class="card-body">
                      <table
                        v-if="completedPrescriptions.length > 0"
                        class="table table-responsive table-hover table-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.completed.table.head1"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.completed.table.head2"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.completed.table.head3"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.completed.table.head4"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.completed.table.head5"
                                )
                              }}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              prescription, index
                            ) in completedPrescriptions"
                            :key="index"
                          >
                            <td>
                              <strong> {{ prescription.id }}</strong>
                            </td>
                            <td>
                              <h2 class="table-avatar">
                                <router-link
                                  to="/provider/patient-profile"
                                  class="avatar avatar-sm mr-2"
                                  ><img
                                    class="avatar-img rounded-circle"
                                    :src="
                                      prescription.patient.profile_image_url
                                    "
                                    alt="User Image"
                                /></router-link>
                                <router-link to="/provider/patient-profile"
                                  >{{
                                    formatLongString(
                                      prescription.patient.first_name
                                    )
                                  }}
                                  {{
                                    formatLongString(
                                      prescription.patient.last_name
                                    )
                                  }}
                                  <span>Request ID: {{ prescription.id }}</span>
                                </router-link>
                              </h2>
                            </td>
                            <td>
                              {{
                                new Date(
                                  prescription.created_at
                                ).toLocaleString()
                              }}
                            </td>
                            <td>
                              <span v-if="prescription.type === 'refill'">
                                {{ normalizeString("Refill") }}
                              </span>
                              <span v-else>
                                {{ normalizeString("Free Text") }}
                              </span>
                            </td>
                            <td>
                              {{ normalizeString(prescription.pharmacy.city) }},
                              {{ prescription.pharmacy.state }}
                            </td>
                            <td class="text-right">
                              <div class="table-action">
                                <a
                                  class="btn btn-sm bg-info-light mr-2"
                                  href="javascript:void();"
                                  data-toggle="modal"
                                  @click="
                                    $router.push(
                                      `/provider/request/${prescription.id}`
                                    )
                                  "
                                >
                                  {{
                                    $t(
                                      "provider.sideBar.dashboard.completed.table.button"
                                    )
                                  }}
                                  <i class="fa fa-angle-right"></i>
                                </a>
                                <a
                                  :id="`cancel-${prescription.id}`"
                                  v-show="
                                    isRxChangeRequest(prescription.medicines)
                                  "
                                  href="javascript:void();"
                                  data-toggle="modal"
                                  class="btn btn-sm bg-warning-light"
                                  @click="showPrescriptionDetailsModal()"
                                >
                                  <i class="fas fa-refresh"></i> RX Change
                                  Requested
                                </a>
                                <!-- <a
                                  :id="`cancel-${prescription.id}`"
                                  href="#prescription-reject-modal"
                                  data-toggle="modal"
                                  class="btn btn-sm bg-danger-light"
                                >
                                  <i class="fas fa-times"></i> Cancel
                                </a> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="p-5 text-center">
                        <h5>
                          {{
                            $t(
                              "provider.sideBar.dashboard.completed.table.noCompleted"
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Completed Prescriptions Tab -->

                <!--Cancelled Prescriptions Tab -->
                <div id="rejected-prescription" class="tab-pane fade">
                  <div class="card card-table mb-0">
                    <div class="card-body">
                      <table
                        v-if="rejectedPrescriptions.length > 0"
                        class="table table-responsive table-hover table-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head1"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head2"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head3"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head4"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head5"
                                )
                              }}
                            </th>
                            <th>
                              {{
                                $t(
                                  "provider.sideBar.dashboard.cancelled.table.head6"
                                )
                              }}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              prescription, index
                            ) in rejectedPrescriptions"
                            :key="index"
                          >
                            <td>
                              <strong> {{ prescription.id }}</strong>
                            </td>
                            <td>
                              <h2 class="table-avatar">
                                <router-link
                                  to="/provider/patient-profile"
                                  class="avatar avatar-sm mr-2"
                                  ><img
                                    class="avatar-img rounded-circle"
                                    :src="
                                      prescription.patient.profile_image_url
                                    "
                                    alt="User Image"
                                /></router-link>
                                <router-link to="/provider/patient-profile"
                                  >{{
                                    formatLongString(
                                      prescription.patient.first_name
                                    )
                                  }}
                                  {{
                                    formatLongString(
                                      prescription.patient.last_name
                                    )
                                  }}
                                  <span>Request ID: {{ prescription.id }}</span>
                                </router-link>
                              </h2>
                            </td>
                            <td>
                              {{
                                new Date(
                                  prescription.created_at
                                ).toLocaleString()
                              }}
                            </td>
                            <td>
                              <span v-if="prescription.type === 'refill'">
                                {{ normalizeString("Refill") }}
                              </span>
                              <span v-else>
                                {{ normalizeString("Free Text") }}
                              </span>
                            </td>
                            <td>
                              <span v-if="prescription.type === 'refill'">
                                {{
                                  normalizeString(prescription.pharmacy.city)
                                }},
                                {{ prescription.pharmacy.state }}
                              </span>
                              <span v-else>
                                {{ normalizeString($store.getters.user.city) }},
                                {{ $store.getters.user.state }}
                              </span>
                            </td>
                            <td>
                              <div v-if="prescription.payment">
                                <span
                                  :class="
                                    `badge badge-pill ` +
                                    paymentStatusBatch(
                                      prescription.payment.status
                                    )
                                  "
                                >
                                  {{
                                    paymentStatus(prescription.payment.status)
                                  }}
                                </span>
                              </div>
                              <div v-else>
                                <span
                                  :class="
                                    `badge badge-pill ` +
                                    paymentStatusBatch('Pending')
                                  "
                                >
                                  {{ paymentStatus("Pending") }}
                                </span>
                              </div>
                            </td>
                            <td class="text-right">
                              <div class="table-action">
                                <a
                                  class="btn btn-sm bg-info-light mr-2"
                                  href="javascript:void();"
                                  @click="
                                    $router.push(
                                      `/provider/request/${prescription.id}`
                                    )
                                  "
                                >
                                  {{
                                    $t(
                                      "provider.sideBar.dashboard.cancelled.table.button"
                                    )
                                  }}
                                  <i class="fa fa-angle-right"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="p-5 text-center">
                        <h5>
                          {{
                            $t(
                              "provider.sideBar.dashboard.cancelled.table.noRequestLabel"
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Cancelled Prescriptions Tab -->
              </div>
              <!-- Tab Content -->
            </div>
          </div>
        </div>
      </div>

      <!-- Reject Modal -->
      <div
        class="modal fade"
        id="prescription-reject-modal"
        style="display: none"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <RejectModal
                v-if="selectedPrescription"
                :prescriptionDetails="selectedPrescription"
                @cancel="rejectPrescription"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Refer Modal -->
      <div class="modal fade" id="refer-modal" style="display: none">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="card-header">
              <h5>Refer a Patient</h5>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <h4 class="mt-2">
                  <small>Referral Code:</small>
                  {{ this.$store.getters.user.referral_code }}
                </h4>
                <button @click="copyCode" class="btn btn-sm btn-info w-100">
                  Copy Code
                </button>
                <hr />
                <form @submit.prevent="sendEmailInvite">
                  <div class="form-group">
                    <h4><small>Invite patient by email address</small></h4>
                    <input
                      type="text"
                      placeholder="Enter Email Address"
                      class="form-control"
                      v-model="referalEmail"
                      required
                    />
                  </div>
                  <button type="submit" class="btn btn-sm btn-primary w-100">
                    Invite by email
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BASE_URL, HTTP_API } from "../../constants/config";
import axios from "axios";
import commonMixin from "../../mixins/common";
import Swal from "sweetalert2";
import RejectModal from "./RejectModal.vue";

const images = require.context(
  "../../assets/img/patients",
  false,
  /\.png$|\.jpg$/
);

export default {
  mixins: [commonMixin],
  components: {
    RejectModal: RejectModal,
  },
  data() {
    return {
      selectedPrescription: null,
      interval: null,
      referalEmail: "",
    };
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  async mounted() {
    this.showLoader(true);
    await this.getIdpStatus();

    if (this.interval) clearInterval(this.interval);

    this.$socket.emit("REQUEST", {
      command: "GET:PRESCRIPTIONS",
      userId: this.$store.getters.user.id,
    });

    this.interval = setInterval(() => {
      this.$socket.emit("REQUEST", {
        command: "GET:PRESCRIPTIONS",
        userId: this.$store.getters.user.id,
      });
    }, 5000);

    function animateElements() {
      $(".circle-bar1").each(function () {
        var elementPos = $(this).offset().top;
        var topOfWindow = $(window).scrollTop();
        var percent = $(this).find(".circle-graph1").attr("data-percent");
        var animate = $(this).data("animate");
        if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
          $(this).data("animate", true);
          $(this)
            .find(".circle-graph1")
            .circleProgress({
              value: percent / 100,
              size: 400,
              thickness: 30,
              fill: {
                color: "#da3f81",
              },
            });
        }
      });
      $(".circle-bar2").each(function () {
        var elementPos = $(this).offset().top;
        var topOfWindow = $(window).scrollTop();
        var percent = $(this).find(".circle-graph2").attr("data-percent");
        var animate = $(this).data("animate");
        if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
          $(this).data("animate", true);
          $(this)
            .find(".circle-graph2")
            .circleProgress({
              value: percent / 100,
              size: 400,
              thickness: 30,
              fill: {
                color: "#68dda9",
              },
            });
        }
      });
      $(".circle-bar3").each(function () {
        var elementPos = $(this).offset().top;
        var topOfWindow = $(window).scrollTop();
        var percent = $(this).find(".circle-graph3").attr("data-percent");
        var animate = $(this).data("animate");
        if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
          $(this).data("animate", true);
          $(this)
            .find(".circle-graph3")
            .circleProgress({
              value: percent / 100,
              size: 400,
              thickness: 30,
              fill: {
                color: "#1b5a90",
              },
            });
        }
      });
    }

    if ($(".circle-bar").length > 0) {
      animateElements();
    }
    $(window).scroll(animateElements);
    $(".progressresult").each(function () {
      const percentageToDegrees = (percentage) => {
        return (percentage / 100) * 360;
      };
      let value = $(this).attr("data-value");
      let left = $(this).find(".progress-left .progress-bar");
      let right = $(this).find(".progress-right .progress-bar");

      if (value > 0) {
        if (value <= 50) {
          right.css(
            "transform",
            "rotate(" + percentageToDegrees(value) + "deg)"
          );
        } else {
          right.css("transform", "rotate(180deg)");
          left.css(
            "transform",
            "rotate(" + percentageToDegrees(value - 50) + "deg)"
          );
        }
      }
    });
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader", "logOut"]),
    async sendEmailInvite() {
      try {
        this.showLoader(true);

        await axios.post(`${BASE_URL}${HTTP_API.providerReferByEmail}`, {
          email: this.referalEmail,
          providerId: this.$store.getters.user.id,
        });

        Swal.fire({
          icon: "success",
          title: "Referral Sent!",
          text: `Invitation has been sent to ${this.referalEmail}`,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.referalEmail = "";
        });

        this.showLoader(false);
        $("#refer-modal").modal("hide");
      } catch (e) {
        $("#refer-modal").modal("hide");
        Swal.fire("Oops!", e.response.data.message, "error");
        this.referalEmail = "";
        this.showLoader(false);
      }
    },
    openReferModal() {
      $("#refer-modal").modal("show");
    },
    copyCode() {
      this.$clipboard(this.$store.getters.user.referral_code)
        .then(() => {
          $("#refer-modal").modal("hide");
          Swal.fire({
            icon: "success",
            title: this.$store.getters.user.referral_code,
            text: `Code copied!`,
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch((e) => {
          $("#refer-modal").modal("hide");
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: `Cannot copy code`,
            showConfirmButton: false,
            timer: 2000,
          });
        });
    },
    formatLongString(str) {
      let s = String(str);
      return s.length > 10 ? s.substring(0, 10) + "..." : s;
    },
    async rejectPrescription(data) {
      try {
        const obj = JSON.parse(data);
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.providerRejectPrescriptions}/${obj.id}/${
            this.$store.getters.user.id
          }`,
          {
            reason: obj.reason,
          }
        );

        this.showLoader(false);
        $("#prescription-reject-modal").modal("hide");
        Swal.fire(
          "Request Cancelled",
          "Prescription request has been rejected",
          "success"
        );
      } catch (e) {
        $("#prescription-reject-modal").modal("hide");
        Swal.fire("Oops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },
    showPrescriptionDetailsModal() {
      $("#prescription-details-modal").modal("show");
    },

    async getIdpStatus() {
      try {
        const res = await axios.get(
          `${BASE_URL + HTTP_API.providerGetIdpStatus}${
            this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`
        );

        if (!res.data.status) {
          this.$router.push("/provider/agreement");
        }
      } catch (e) {
        this.showLoader(false);
        Swal.fire(
          "Account Suspended",
          "Your account is inactive or it has been suspended by the administrator. Please contact the support team.",
          "error"
        ).then(() => {
          this.logout();
        });
      }
    },

    async logout() {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
        this.$router.go();
      } catch (e) {
        console.error(e);
      }
    },

    paymentStatusBatch(status) {
      switch (status) {
        case "Pending":
          return "bg-danger-light";

        case "COMPLETED":
          return "bg-success-light";

        default:
          return "bg-danger-light";
      }
    },

    pharmacyStatusBatch(medicines) {
      const isRefillInitiated = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_refill_initiated
      );
      if (isRefillInitiated) return "bg-success-light";

      const isRxChangeRequest = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_rxchange_requested
      );
      if (isRxChangeRequest) return "bg-danger-light";

      const isPharmacyVerified = medicines.find((m) => m.dsp_status_id === 13);
      if (isPharmacyVerified) return "bg-success-light";

      return "bg-warning-light";
    },

    cancellationStatusBatch(status) {
      if (status === "Completed") {
        return "bg-success-light";
      } else {
        return "bg-danger-light";
      }
    },

    paymentStatus(status) {
      switch (status) {
        case "Pending":
          return "NOT PAID";

        case "COMPLETED":
          return "COMPLETED";

        default:
          return "NOT PAID";
      }
    },

    pharmacyStatus(medicines) {
      const isRefillInitiated = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_refill_initiated
      );
      if (isRefillInitiated) return "REFILL INITIATED";

      const isRxChangeRequest = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_rxchange_requested
      );

      if (isRxChangeRequest) return "RX CHANGE REQUESTED";

      const isPharmacyVerified = medicines.find((m) => m.dsp_status_id === 13);
      if (isPharmacyVerified) return "SENT TO PHARMACY";

      return "WAITING FOR PHARMACY";
    },

    isRxChangeRequest(medicinesArray) {
      return medicinesArray.filter((med) => med.is_rxchange_requested).length >
        0
        ? true
        : false;
    },

    closeEvent() {
      $("#prescription-details-modal").modal("toggle");
    },
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    },
    getCancellationStatus(medicines) {
      return medicines.filter((i) => i.dspCancellationStatus == "In Process")
        .length > 0
        ? "In Process"
        : "Cancelled";
    },
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions
        ? this.$store.getters.prescriptions
        : [];
    },
    pendingPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "In Progress");
    },
    acceptedPrescriptions() {
      return this.prescriptions.filter(
        (i) =>
          i.status === "Accepted" ||
          i.status === "PharmacyVerified" ||
          i.status === "Sent"
      );
    },
    completedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Completed");
    },
    rejectedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Cancelled");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>
