<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        <div class="form-group">
          <div class="change-avatar">
            <div class="upload-img">
              <div class="change-photo-btn mt-3 w-100">
                <span
                  ><i class="fa fa-upload"></i>
                  {{
                    $t(
                      "provider.sideBar.accountSetting.profileSettings.changePhotoTitle"
                    )
                  }}</span
                >
                <input type="file" class="upload" id="profile_image" />
              </div>
              <small class="text-muted">{{
                $t(
                  "provider.sideBar.accountSetting.profileSettings.photoDescription"
                )
              }}</small>
            </div>
          </div>
        </div>
      </h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="saveProfileDetails">
        <div class="row form-row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>{{
                $t(
                  "provider.sideBar.accountSetting.profileSettings.firstNameLabel"
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="firstName"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t(
                    "provider.sideBar.accountSetting.profileSettings.lastNameLabel"
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                v-model="lastName"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label
                >{{
                  $t("provider.sideBar.accountSetting.profileSettings.aboutMe")
                }}
                <span class="text-muted" style="font-size: 10px"
                  >({{ String(aboutMe).length }} of 500)</span
                ></label
              >
              <textarea
                type="text"
                class="form-control"
                placeholder="Tell us something about yourself that you would like to showcase. Remember, your patient's can see this :)"
                v-model="aboutMe"
                minlength="0"
                maxlength="500"
                rows="6"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t(
                    "provider.sideBar.accountSetting.profileSettings.dateOfBirth"
                  )
                }}
              </label>
              <Datepicker
                :lowerLimit="dateLowerLimit"
                :upperLimit="dateUpperLimit"
                inputFormat="MM-dd-yyyy"
                class="form-control"
                v-model="dob"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t("provider.sideBar.accountSetting.profileSettings.gender")
                }}
              </label>
              <select v-model="gender" class="form-control select" required>
                <option value="Male">
                  {{
                    $t("provider.sideBar.accountSetting.profileSettings.male")
                  }}
                </option>
                <option value="Female">
                  {{
                    $t("provider.sideBar.accountSetting.profileSettings.female")
                  }}
                </option>
                <option value="Other">
                  {{
                    $t(
                      "provider.sideBar.accountSetting.profileSettings.notToSay"
                    )
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t("provider.sideBar.accountSetting.profileSettings.emailId")
                }}
              </label>
              <input
                type="email"
                class="form-control"
                v-model="$store.getters.user.email"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t("provider.sideBar.accountSetting.profileSettings.mobile")
                }}
              </label>
              <input
                type="text"
                v-model="$store.getters.user.primary_phone"
                class="form-control"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>{{
                $t(
                  "provider.sideBar.accountSetting.profileSettings.addressLine1"
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="addressLine1"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>{{
                $t(
                  "provider.sideBar.accountSetting.profileSettings.addressLine2"
                )
              }}</label>
              <input type="text" class="form-control" v-model="addressLine2" />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t("provider.sideBar.accountSetting.profileSettings.state")
                }}
              </label>
              <select
                class="form-control select"
                v-model="state"
                required
                @change="fetchCities()"
              >
                <option
                  v-for="(state, i) in statesData"
                  :key="i"
                  :value="state.state_code"
                >
                  {{ state.state_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{ $t("provider.sideBar.accountSetting.profileSettings.city") }}
              </label>
              <select class="form-control select" v-model="city" required>
                <option
                  v-for="(city, i) in citiesData"
                  :key="i"
                  :value="city.city_name"
                >
                  {{ city.city_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>{{
                $t("provider.sideBar.accountSetting.profileSettings.postalCode")
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="zipCode"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label>
                {{
                  $t("provider.sideBar.accountSetting.profileSettings.country")
                }}
              </label>
              <input
                type="text"
                class="form-control"
                value="United States"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="submit-section text-center">
          <button type="submit" class="btn btn-primary">
            {{
              $t("provider.sideBar.accountSetting.profileSettings.saveButton")
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../../mixins/common";
import Datepicker from "vue3-datepicker";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  data() {
    return {
      firstName: "",
      lastName: "",
      aboutMe: "",
      gender: "",
      dob: new Date(2003, 11, 31),
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(new Date().getFullYear() - 19, 11, 31),
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
      photo: null,
    };
  },
  components: {
    Datepicker,
  },

  async mounted() {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    let user = JSON.parse(JSON.stringify(this.$store.getters.user));

    // Personal details
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.aboutMe = user.about_me;
    this.gender = user.gender;
    this.dob = new Date(user.dob);

    // Address parsing
    this.addressLine1 = user.address1;
    this.addressLine2 = user.address2;
    this.zipCode = user.zip_code;

    this.state = user.profile_state;
    if (this.state !== "") await this.fetchCities(this.state);

    this.city = user.city;

    let self = this;
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = async function (e) {
          $("#prof-avatar").attr("src", e.target.result);
          await self.savePhoto();
          self.$router.go();
        };

        reader.readAsDataURL(input.files[0]);
        self.photo = input.files[0];
      }
    }

    $("#profile_image").change(function () {
      readURL(this);
    });

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "saveUserProfilePhoto", "showLoader"]),
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async savePhoto() {
      try {
        this.showLoader(true);
        let formData = new FormData();
        formData.append("photo", this.photo);
        formData.append("id", this.$store.getters.user.id);
        formData.append("currentRegistrationStep", 0);
        await this.saveUserProfilePhoto(formData);
        Swal.fire("Profile Updated!", "", "success");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
        console.error(e);
      }
    },

    async saveProfileDetails() {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          first_name: this.firstName,
          last_name: this.lastName,
          about_me: this.aboutMe,
          dob: formated,
          gender: this.gender,
          address1: this.addressLine1,
          address2: this.addressLine2,
          state: this.state,
          city: this.city,
          zip_code: this.zipCode,
        });

        Swal.fire("Profile Updated!", "", "success");

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Failed!", e.response.data.message, "error");
        console.error(e);
      }
    },
  },
};
</script>
