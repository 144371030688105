<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-11 col-8">
              <h2 class="breadcrumb-title">
                {{ $t("patient.newPrescription.title") }}
              </h2>
            </div>
            <div class="col-lg-1 col-4">
              <router-link to="/patient/index">
                <button class="btn btn-light btn-large btn-block">
                  {{ $t("patient.newPrescription.cancelbtn") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="submitRequest">
                <h3 class="card-title text-center m-2">
                  {{ $t("patient.newPrescription.title") }}
                </h3>
                <hr />
                <div class="row">
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="name-input" class="form-label">
                        {{
                          $t("patient.newPrescription.fullNameLabel")
                        }}*</label>
                      <input type="text" class="form-control" id="name-input" v-model="newPrescription.name"
                        placeholder="Enter your full name" required :disabled="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="mobile-input" class="form-label">{{
                        $t("patient.newPrescription.mobileNoLabel")
                      }}*</label>
                      <input type="text" class="form-control" id="mobile-input" v-model="newPrescription.mobileNumber"
                        placeholder="Enter your mobile number" required :disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="height-input" class="form-label">{{ $t("patient.newPrescription.heightLabel")
                        }}*</label>
                      <input type="number" class="form-control" id="height-input" v-model="newPrescription.height"
                        required :disabled="true" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="weight-input" class="form-label">{{ $t("patient.newPrescription.weightLabel")
                        }}*</label>
                      <input type="number" class="form-control" id="weight-input" v-model="newPrescription.weight"
                        required :disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <label for="symptoms-input" class="form-label">
                        {{ $t("patient.newPrescription.healthConditionLabel") }}*
                      </label>
                      <textarea class="form-control" id="symptoms-input" rows="3"
                        v-model="newPrescription.healthSymptoms"
                        :placeholder="$t('patient.newPrescription.healthConditionPlaceholder')" required></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Pharmacy Details*</label>
                      <div class="p-2" v-if="pharmacy && pharmacy.pharmacyName">
                        <div class="row">
                          <div class="col-8">
                            <h5>
                              {{ $t("patient.newPrescription.nameLabel") }}: {{ pharmacy.pharmacyName }}
                            </h5>
                          </div>
                          <div class="col-4">
                            <button type="button" class="btn btn-sm btn-outline-secondary btn-block" data-toggle="modal"
                              data-target=".bd-example-modal-xl">
                              {{ $t("patient.newPrescription.changebtn") }}
                            </button>
                          </div>
                        </div>

                        <h6>
                          {{ $t("patient.newPrescription.phoneLabel") }}: {{ pharmacy.phone }}
                        </h6>
                        <h6>
                          {{ $t("patient.newPrescription.addressLabel") }}: {{ getStoreAddress(pharmacy.address) }}
                        </h6>
                      </div>
                      <div class="p-2" v-else>
                        <button type="button" class="btn btn-outline-secondary btn-block" data-toggle="modal"
                          data-target=".bd-example-modal-xl">
                          Select Pharmacy
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Select Pharmacy Modal -->
                  <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog"
                    aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">
                            {{ $t("patient.newPrescription.title2") }}
                          </h5>
                          <button type="button" class="btn btn-info" data-dismiss="modal" id="close">
                            {{ $t("patient.newPrescription.closebtn") }}
                          </button>
                        </div>

                        <div class="modal-body">
                          <ChoosePharmacy @select="selectPharmacy" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="medical-past-input" class="form-label">
                        {{
                          $t(
                            "patient.newPrescription.pastMedicalConditionLabel"
                          )
                        }}</label>
                      <textarea class="form-control" id="medical-past-input" rows="3"
                        v-model="newPrescription.medicalPast" :placeholder="$t(
                          'patient.newPrescription.pastMedicalConditionPlaceholder'
                        )
                          "></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="mb-3">
                      <label for="allergies-input" class="form-label">{{
                        $t("patient.newPrescription.allergiesLabel")
                      }}</label>
                      <textarea class="form-control" id="allergies-input" v-model="newPrescription.allergies"
                        :placeholder="$t('patient.newPrescription.allergiesPlaceholder')
                          " rows="3"></textarea>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <div class="text-center">
                      <button type="submit" class="btn btn-info">
                        <i class="fa fa-send mr-2"></i>
                        {{ $t("patient.newPrescription.submitRequestbtn") }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import config, { HTTP_API } from "../../constants/config";
import axios from "axios";
import Swal from "sweetalert2";
import ChoosePharmacy from "./ChoosePharmacy.vue";
export default {
  components: { ChoosePharmacy },
  data() {
    return {
      newPrescription: {},
      pharmacy: null,
    };
  },
  mounted() {
    this.newPrescription.name = `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}`;
    this.newPrescription.mobileNumber = `${this.$store.getters.user.primary_phone}`;
    this.newPrescription.height = `${String(this.$store.getters.user.height)}`;
    this.newPrescription.weight = `${String(this.$store.getters.user.weight)}`;
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    async submitRequest() {
      try {
        if (!this.pharmacy || !this.pharmacy.pharmacyName) {
          Swal.fire("Select Pharmacy", "Please select the pharmacy", "error");
          return;
        }

        this.showLoader(true);

        const res = await axios.post(
          config.BASE_URL + HTTP_API.patientCreateFreePrescription,
          {
            id: this.$store.getters.user.id,
            patientId: this.$store.getters.user.patientId,
            ...this.newPrescription,
            pharmacy: this.pharmacy,
          }
        );

        Swal.fire({
          icon: "success",
          title: `Rx Request Created!`,
          text: `Redirecting to request payments...`,
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          this.$router.push(`/patient/billing/${res.data.prescriptionNumber}`);
        });

        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Request Failed!", "Failed to send the request", "error");
        this.showLoader(false);
      }
    },

    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },

    selectPharmacy(data) {
      this.pharmacy = JSON.parse(data);
      document.getElementById("close").click();
    },
  },
};
</script>
<style scoped>
.section-search {
  min-height: 600px;
  padding: 40px 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>
