<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <h5>Rx Refills</h5>
        </div>
        <div
          style="overflow: auto; height: 550px"
          v-if="
            medicine &&
            medicine.pharmacy_refills &&
            medicine.pharmacy_refills.length > 0
          "
        >
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(refill, index) in medicine.pharmacy_refills"
              :key="index"
              @click="selectRefill(refill)"
              style="cursor: pointer"
              :class="
                selectedRefillObj && selectedRefillObj.id == refill.id
                  ? 'btn-info'
                  : ''
              "
            >
              <p class="mb-2">
                {{
                  $t(
                    "provider.prescriptionDetails.medications.medicines.refillId"
                  )
                }}:
                <span> {{ refill.refill_id }}</span>
              </p>
              <p class="mb-2">
                Message ID:
                {{ refill.dsp_prescription_id }}
              </p>
              <p class="mb-2">
                {{
                  $t("provider.prescriptionDetails.medications.medicines.date")
                }}:
                {{ new Date(refill.requested_date).toLocaleString() }}
              </p>
              <p class="mb-2">
                Status:
                <span
                  v-if="
                    medicine.selected_refill.refill_id === refill.refill_id &&
                    medicine.selected_refill.status === 'Denied'
                  "
                  class="badge bg-danger"
                  >Denied</span
                >
                <span
                  v-else-if="
                    medicine.selected_refill.refill_id === refill.refill_id &&
                    medicine.selected_refill.status === 'Completed'
                  "
                  class="badge bg-success"
                  >{{
                    $t(
                      "provider.prescriptionDetails.medications.medicines.approved"
                    )
                  }}</span
                >
                <span
                  v-else="
                    medicine.selected_refill.refill_id === refill.refill_id
                  "
                  class="badge bg-success"
                  >Selected</span
                >
              </p>
            </li>
          </ul>
        </div>
        <div v-else class="text-center" style="overflow: auto; height: 550px">
          <p class="m-5">
            {{
              $t(
                "provider.prescriptionDetails.medications.medicines.nothingHere"
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div
        class="card"
        v-if="medicine.selected_refill && medicine.pharmacy_refills"
      >
        <div class="card-header">
          <h5>Pharmacy Refill Details</h5>
        </div>
        <div class="card-body" style="overflow: auto; height: 550px">
          <!-- Refill Object -->
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.quantity"
                      )
                    }}</strong></label
                  >
                  <input
                    type="number"
                    class="form-control form-control-xs"
                    v-model="medicine.selected_refill.quantity"
                    min="1"
                    max="5000"
                    step="any"
                    @change="editInProgress"
                    :disabled="disabled"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.unit"
                      )
                    }}</strong></label
                  >
                  <select
                    class="form-control select form-control-xs"
                    tabindex="-1"
                    aria-hidden="true"
                    v-model="medicine.selected_refill.unit_id"
                    @change="editInProgress"
                    required
                    :disabled="disabled"
                  >
                    <option
                      v-for="(unit, i) in units"
                      :key="i"
                      :value="unit.StandardDispenseUnitTypeID"
                    >
                      {{ unit.Plural }} ({{ unit.Abbreviation }})
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.refillsRequested"
                      )
                    }}</strong></label
                  >
                  <input
                    type="number"
                    class="form-control form-control-xs"
                    v-model="medicine.selected_refill.refills"
                    min="0"
                    max="99"
                    @change="editInProgress"
                    :disabled="disabled"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.daysSupply"
                      )
                    }}</strong></label
                  >
                  <input
                    type="number"
                    class="form-control form-control-xs"
                    v-model="medicine.selected_refill.supply_days"
                    min="1"
                    max="999"
                    @change="editInProgress"
                    :disabled="disabled"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.description"
                      )
                    }}</strong></label
                  >
                  <textarea
                    class="form-control form-control-xs"
                    @keydown=""
                    v-model="medicine.selected_refill.directions"
                    placeholder="Directions of use"
                    :disabled="disabled"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group text-left">
                  <label
                    ><strong>{{
                      $t(
                        "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                      )
                    }}</strong></label
                  >
                  <textarea
                    class="form-control form-control-xs"
                    @keydown="editInProgress"
                    v-model="medicine.selected_refill.pharmacy_note"
                    placeholder="Notes to Pharmacy"
                    :disabled="disabled"
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              class="row"
              v-if="
                !isEditing &&
                medicine.selected_refill.status !== 'Completed' &&
                medicine.selected_refill.status !== 'Denied'
              "
            >
              <div class="col-md-6">
                <button
                  @click="
                    showQuickDenyModal(
                      medicine.prescription_id,
                      medicine.selected_refill.refill_id
                    )
                  "
                  class="btn btn-sm bg-warning-light btn-block"
                >
                  {{
                    $t(
                      "provider.prescriptionDetails.medications.medicines.denyRefill"
                    )
                  }}
                </button>
              </div>
              <div class="col-md-6">
                <button
                  @click="
                    showQuickApproveModal(
                      medicine.prescription_id,
                      medicine.selected_refill.refill_id
                    )
                  "
                  class="btn btn-sm bg-success-light btn-block"
                >
                  {{
                    $t(
                      "provider.prescriptionDetails.medications.medicines.approveRefill"
                    )
                  }}
                </button>
              </div>
            </div>
            <div class="row" v-if="isEditing">
              <div class="col-md-12">
                <button
                  @click="savePrescriptionDetails"
                  class="btn bg-info-light btn-block"
                >
                  {{
                    $t(
                      "provider.prescriptionDetails.medications.medicines.savePrescription"
                    )
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionDetails: Object,
    medicine: Object,
    units: Array,
  },
  watch: {
    prescriptionDetails: function (old, newVal) {
      console.log(this.prescriptionDetails);
    },
    medicine: function (old, newVal) {
      this.selectedRefillObj =
        this.medicine && this.medicine.pharmacy_refills
          ? this.medicine.pharmacy_refills[0]
          : null;
    },
  },
  computed: {
    disabled() {
      return (
        this.medicine &&
        (this.medicine.selected_refill.status == "Completed" ||
          this.medicine.selected_refill.status == "Denied")
      );
    },
  },
  data() {
    return {
      selectedRefillObj: null,
      isEditing: false,
    };
  },
  mounted() {
    this.selectedRefillObj =
      this.medicine && this.medicine.pharmacy_refills
        ? this.medicine.pharmacy_refills[0]
        : null;
  },
  methods: {
    selectRefill(refillObj) {
      this.selectedRefillObj = refillObj;
    },
    showQuickApproveModal(prescriptionId, refillId) {
      this.$emit("showQuickApproveModal", {
        prescriptionId: prescriptionId,
        refillId: refillId,
      });
    },
    showQuickDenyModal(prescriptionId, refillId) {
      this.$emit("showQuickDenyModal", {
        prescriptionId: prescriptionId,
        refillId: refillId,
      });
    },
    savePrescriptionDetails() {
      this.isEditing = false;
    },
    editInProgress() {
      this.isEditing = true;
    },
  },
};
</script>
<style scoped>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
