<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-header">
          <h5>
            {{
              $t(
                "provider.sideBar.accountSetting.providerSettings.licenseStates.title"
              )
            }}
          </h5>
        </div>
        <div class="card-body">
          <form @submit.prevent="saveStep">
            <div class="form-group">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="form-group text-left">
                    <label>{{
                      $t(
                        "provider.sideBar.accountSetting.providerSettings.licenseStates.primaryState"
                      )
                    }}</label>
                    <input
                      type="text"
                      name="address2"
                      class="form-control"
                      v-model="primaryState"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-12 col-md-8">
                  <div class="form-group text-left">
                    <label>
                      {{
                        $t(
                          "provider.sideBar.accountSetting.providerSettings.licenseStates.primaryStateLicenseNumber"
                        )
                      }}
                    </label>
                    <input
                      type="text"
                      name="state-license-number"
                      class="form-control"
                      placeholder="Max 35 Chars"
                      max="35"
                      v-model="stateLicenseNumber"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row" v-for="(st, i) in additionalStates" :key="i">
                <div class="col-12 text-right">
                  <img
                    src="../../../assets/img/icons/close.png"
                    alt="close"
                    width="26"
                    class="delete-btn"
                    @click="removeState(i)"/>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group text-left">
                    <label>
                      {{
                        $t(
                          "provider.sideBar.accountSetting.providerSettings.licenseStates.state"
                        )
                      }}
                      {{ i + 1 }}</label
                    >
                    <input
                      type="text"
                      name="address2"
                      class="form-control"
                      :value="st.state_code"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-12 col-md-7">
                  <div class="form-group text-left">
                    <label
                      >{{
                        $t(
                          "provider.sideBar.accountSetting.providerSettings.licenseStates.stateLicenseNumber"
                        )
                      }}
                      {{ i + 1 }}</label
                    >
                    <input
                      type="text"
                      name="state-license-number"
                      class="form-control"
                      placeholder="Max 35 Chars"
                      v-model="st.state_license_number"
                      required
                    />
                  </div>
                </div>
              </div>

              <form @submit.prevent="addNewState">
                <div class="row" v-show="showNewStateForm">
                  <div class="col-12 col-md-7">
                    <div class="form-group">
                      <select
                        class="form-control select"
                        tabindex="-1"
                        aria-hidden="true"
                        v-model="newState"
                        required
                      >
                        <option
                          v-for="(state, i) in statesData"
                          :key="i"
                          :value="state.state_code"
                        >
                          {{ state.state_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-5">
                    <button
                      :disabled="newState === ''"
                      type="submit"
                      data-dismiss="modal"
                      class="btn btn-primary btn-block"
                    >
                      Add State
                    </button>
                  </div>
                </div>
              </form>
              <div class="text-center">
                <a
                  href="javascript:void();"
                  class="btn btn-info btn-sm btn-block"
                  v-show="!showNewStateForm"
                  @click="showNewStateForm = true"
                >
                  <i class="fa fa-plus mr-2"></i
                  >{{
                    $t(
                      "provider.sideBar.accountSetting.providerSettings.licenseStates.button1"
                    )
                  }}</a
                >
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary btn-block mt-2">
                  <i class="fa fa-save mr-2"></i>
                  {{
                    $t(
                      "provider.sideBar.accountSetting.providerSettings.licenseStates.button2"
                    )
                  }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card">
        <div class="card-header">
          <h5>
            {{
              $t(
                "provider.sideBar.accountSetting.providerSettings.licenseDetails.title"
              )
            }}
          </h5>
        </div>
        <div class="card-body">
          <div class="row form-row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.npiId"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="$store.getters.user.npi"
                  required
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.licenseNo"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="$store.getters.user.license"
                  required
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.description"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="$store.getters.user.description"
                  required
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.enumerationType"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="$store.getters.user.enumeration_type"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.credential"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="$store.getters.user.credential"
                  required
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{
                  $t(
                    "provider.sideBar.accountSetting.providerSettings.licenseDetails.certificateDate"
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  :value="
                    new Date(
                      $store.getters.user.enumeration_date
                    ).toLocaleDateString()
                  "
                  required
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { mapActions } from "vuex";
import common from "../../../mixins/common";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  data() {
    return {
      primaryState: "",
      stateLicenseNumber: "",
      newState: "",
      statesData: [],
      additionalStates: [],
      showNewStateForm: false,
    };
  },

  async mounted() {
    this.showLoader(true);

    this.primaryState = this.$store.getters.user.state;

    for (let state of this.$store.getters.user.additional_states) {
      if (state.type === "primary")
        this.stateLicenseNumber = state.state_license_number;
    }

    this.additionalStates = this.$store.getters.user.additional_states.filter(
      (a) => a.type !== "primary"
    );

    this.statesData = await this.getStateList();

    this.statesData.splice(
      this.statesData.map((i) => i.iso2).indexOf(this.primaryState),
      1
    );
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),

    addNewState() {
      this.additionalStates.push({
        state_code: this.newState,
        state_license_number: "",
      });
      this.newState = "";
      this.showNewStateForm = false;
    },

    removeState(index) {
      this.additionalStates.splice(index, 1);
    },

    async saveStep() {
      try {
        this.showLoader(true);
        let step = 6;
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          additional_states: [
            ...this.additionalStates,
            {
              state_code: this.primaryState,
              state_license_number: this.stateLicenseNumber,
              type: "primary",
            },
          ],
          current_registration_step: step,
        });

        Swal.fire({
          icon: "success",
          title: "States Updated!",
          text: ``,
          showConfirmButton: false,
          timer: 2000,
        });

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: "Ooops!",
          text: e.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error(e);
      }
    },
  },
};
</script>


<style scoped>
  /* Add custom responsive styles */
  @media (max-width: 768px) {
    .form-group label {
      font-size: 14px; /* Smaller label font for mobile */
    }
    .form-control {
      font-size: 14px; /* Adjust input font size */
      padding: 10px; /* Add padding for better touch experience */
    }
    .btn {
      font-size: 14px; /* Make buttons slightly smaller */
      padding: 10px;
    }
  }
</style>