<template>
  <!-- Footer -->
  <footer :class="$store.getters.user ? `footer` : `footer`">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <h2 class="footer-title">
                  {{ $t("homePage.footerSection.certified") }}
                </h2>
                <div class="row">
                  <div class="col-6 mt-2">
                    <img width="180" src="../../assets/img/Surescripts_certified-lowres.jpg" alt="logo"
                      style="border-radius: 10px" />
                  </div>
                  <div class="col-6">
                    <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval">
                      <img src="https://static.legitscript.com/seals/16924705.png" alt="LegitScript approved" width="100"
                        border="0" />
                    </a>
                  </div>
                </div>
             

              </div>
              <div class="footer-about-content">
                <div class="footer-logo">
                  <img width="250" src="../../assets/img/footer-logo.png" alt="logo" />
                  <p class="mt-3">
                    {{ $t("homePage.footerSection.description") }}
                  </p>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">
                {{ $t("homePage.footerSection.userfulLinks.title") }}
              </h2>
              <ul>
                <li>
                  <router-link to="/provider/register" @click="moveUp">{{
                    $t("homePage.footerSection.userfulLinks.link1")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/patient/register" @click="moveUp">{{
                    $t("homePage.footerSection.userfulLinks.link2")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/login" @click="moveUp">{{
                    $t("homePage.footerSection.userfulLinks.link3")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="term-condition" @click="moveUp">{{
                    $t("homePage.footerSection.userfulLinks.link4")
                  }}</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">
                {{ $t("homePage.footerSection.getToKnowUs.title") }}
              </h2>
              <ul>
                <li>
                  <router-link to="/about" @click="moveUp">{{
                    $t("homePage.footerSection.getToKnowUs.link1")
                  }}</router-link>
                </li>
                <!-- <li>
                  <router-link to="/patient/chat">Chat</router-link>
                </li> -->
                <li>
                  <router-link to="/about" @click="moveUp">{{
                    $t("homePage.footerSection.getToKnowUs.link2")
                  }}</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">
                {{ $t("homePage.footerSection.contactUs.title") }}
              </h2>
              <div class="footer-contact-info">
                <p>
                  <i class="fas fa-phone-alt"></i>
                  {{ $t("homePage.footerSection.contactUs.tollFreeNumber") }}
                </p>
                <p>
                  <i class="fas fa-volume-control-phone"></i>
                  {{ $t("homePage.footerSection.contactUs.phone") }}
                </p>
                <p class="mb-0">
                  <i class="fas fa-envelope"></i>
                  {{ $t("homePage.footerSection.contactUs.email") }}
                </p>
              </div>
            </div>
            <div class="social-icon mt-3">
              <ul>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#" target="_blank"><i class="fab fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->
    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container-fluid">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  &copy; {{ $t("homePage.footerSection.copyWrite") }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/term-condition" @click="moveUp">{{
                      $t("homePage.footerSection.termsAndConditions")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy" @click="moveUp">{{
                      $t("homePage.footerSection.privacyPolicy")
                    }}</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
<script>
export default {
  methods: {
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
