<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-prescriptions-center">
            <div class="col-md-12 col-12">
              <h2 class="breadcrumb-title">
                {{ $t("provider.sideBar.allPrescription.heading") }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickyside">
                <sidebar></sidebar>
              </div>
            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">

                    <div class="card-header row">
                      <div class="col-12 col-md-6">
                        <h4 class="card-title">
                          {{ $t("provider.sideBar.allPrescription.heading") }}
                        </h4>
                      </div>
                    </div>

                    <!-- <div class="card-header row">
                      <div class="col-6">
                        <h4 class="card-title">
                          {{ $t("provider.sideBar.allPrescription.heading") }}
                        </h4>
                      </div>
                    </div> -->

                    <!-- web view table -->
                    <div class="card-body webViewTable">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    {{
                                      $t(
                                        "provider.sideBar.allPrescription.table.head1"
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t(
                                        "provider.sideBar.allPrescription.table.head2"
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t(
                                        "provider.sideBar.allPrescription.table.head3"
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t(
                                        "provider.sideBar.allPrescription.table.head4"
                                      )
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t(
                                        "provider.sideBar.allPrescription.table.head5"
                                      )
                                    }}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(prescription, i) in prescriptions" :key="i">
                                  <td>
                                    <strong>{{ prescription.id }}</strong>
                                  </td>
                                  <td>
                                    {{
                                      new Date(
                                        prescription.created_at
                                      ).toLocaleString()
                                    }}
                                  </td>
                                  <td>
                                    <span v-if="prescription.type === 'refill'">
                                      {{ normalizeString("Refill") }}
                                    </span>
                                    <span v-else>
                                      {{ normalizeString("Free Text") }}
                                    </span>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar" v-if="
                                      (prescription.patient &&
                                        prescription.status ===
                                        'In Progress') ||
                                      prescription.status === 'Accepted' ||
                                      prescription.status === 'Completed' ||
                                      prescription.status === 'Cancelled'
                                    ">
                                      <a v-if="prescription.patient" href="javascript:void();"
                                        class="avatar avatar-sm mr-2">
                                        <img class="avatar-img rounded-circle" :src="prescription.patient
                                            .profile_image_url
                                          " alt="User Image" />
                                      </a>
                                      <a href="javascript:void();">{{ prescription.patient.first_name }}
                                        {{ prescription.patient.last_name }}
                                        <span>{{
                                          prescription.patient.description
                                        }}</span>
                                      </a>
                                    </h2>
                                    <h2 class="table-avatar" v-else>
                                      <a href="javascript:void();">Not Available
                                      </a>
                                    </h2>
                                  </td>
                                  <td>
                                    <a href="javascript:void(0);" :class="getStatusColor(prescription.status)
                                      ">
                                      {{ prescription.status }}
                                    </a>
                                  </td>
                                  <td class="text-right">
                                    <div class="table-action">
                                      <a href="#prescription-details-modal" data-toggle="modal"
                                        class="btn btn-sm bg-info-light" @click="
                                          $router.push(
                                            `/provider/request/${prescription.id}`
                                          )
                                          ">
                                        <i class="far fa-eye"></i>
                                        {{
                                          $t(
                                            "provider.sideBar.allPrescription.table.button"
                                          )
                                        }}
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

const images = require.context(
  "../../assets/img/doctors",
  false,
  /\.png$|\.jpg$/
);

export default {
  data() {
    return {};
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions
        ? this.$store.getters.prescriptions
        : [];
    },
  },
  methods: {
    ...mapActions(["showLoader"]),
    normalizeString(str) {
      return (
        String(str).charAt(0).toUpperCase() + String(str).slice(1).toLowerCase()
      );
    },
    getStatusColor(status) {
      switch (status) {
        case "Pending":
          return "btn btn-sm bg-warning-light mr-1";

        case "Assigned":
          return "btn btn-sm bg-primary-light mr-1";

        case "Completed":
          return "btn btn-sm bg-success-light mr-1";

        case "Accepted":
          return "btn btn-sm bg-primary-light mr-1";

        case "Cancelled":
          return "btn btn-sm bg-danger-light mr-1";

        default:
          return "btn btn-sm bg-primary-light mr-1";
      }
    },
  },
};
</script>
