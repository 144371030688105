<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          <h5>Prescription History</h5>
        </div>
        <div
          style="overflow: auto; height: 550px"
          v-if="
            medicine &&
            medicine.old_prescriptions &&
            medicine.old_prescriptions.length > 0
          "
        >
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(prescription, index) in medicine.old_prescriptions"
              :key="index"
              @click="selectPrescription(prescription)"
              style="cursor: pointer"
              :class="
                selectedPrescription &&
                selectedPrescription.id == prescription.id
                  ? 'btn-info'
                  : ''
              "
            >
              <p class="mb-2">
                Rx ID:
                <span> {{ prescription.dsp_prescription_id }}</span>
              </p>
              <!-- <p class="mb-2">
                Message ID:
                <span> {{ prescription.requested_dsp_prescription_id }}</span>
              </p> -->
              <p class="mb-2">
                {{
                  $t("provider.prescriptionDetails.medications.medicines.date")
                }}:
                {{ new Date(prescription.created_at).toLocaleString() }}
              </p>
              <p class="mb-2">Status: Retired</p>
            </li>
          </ul>
        </div>
        <div v-else class="text-center" style="overflow: auto; height: 550px">
          <p class="m-5">
            {{
              $t(
                "provider.prescriptionDetails.medications.medicines.nothingHere"
              )
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card" v-if="selectedPrescription">
        <div class="card-header">
          <div class="row">
            <div class="col-md-8">
              <h5>Rx ID: {{ selectedPrescription.dsp_prescription_id }}</h5>
            </div>
            <div class="col-md-4 text-right">
              <small>{{
                new Date(selectedPrescription.created_at).toLocaleString()
              }}</small>
            </div>
          </div>
        </div>
        <div class="card-body p-0" style="overflow: auto; height: 550px">
          <div
            v-if="selectedPrescription"
            class="accordion"
            id="requested-prescription-accordion"
            style="font-size: 12px"
          >
            <div
              class="card-header p-2"
              :id="`${selectedPrescription.id}-requested-rx-accordion`"
            >
              <h2 class="mb-0">
                <button
                  style="font-size: 14px"
                  class="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  :data-target="`#${selectedPrescription.id}-requested-rx-collapse`"
                  :aria-controls="`${selectedPrescription.id}-requested-rx-collapse`"
                >
                  {{ selectedPrescription.medicine_name }}
                  <small
                    >(Rx ID:
                    {{ selectedPrescription.dsp_prescription_id }})</small
                  >
                </button>
              </h2>
            </div>
            <div
              :id="`${selectedPrescription.id}-requested-rx-collapse`"
              class="collapse show"
              :aria-labelledby="`${selectedPrescription.id}-requested-rx-accordion`"
              data-parent="#requested-prescription-accordion"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.quantity"
                          )
                        }}</strong></label
                      >
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="selectedPrescription.quantity"
                        min="1"
                        max="5000"
                        step="any"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.unit"
                          )
                        }}</strong></label
                      >
                      <select
                        class="form-control select form-control-xs"
                        tabindex="-1"
                        aria-hidden="true"
                        v-model="selectedPrescription.unit_id"
                        required
                        :disabled="true"
                      >
                        <option
                          v-for="(unit, i) in units"
                          :key="i"
                          :value="unit.StandardDispenseUnitTypeID"
                        >
                          {{ unit.Plural }} ({{ unit.Abbreviation }})
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.refillsRequested"
                          )
                        }}</strong></label
                      >
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="selectedPrescription.refills"
                        min="0"
                        max="99"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.daysSupply"
                          )
                        }}</strong></label
                      >
                      <input
                        type="number"
                        class="form-control form-control-xs"
                        v-model="selectedPrescription.supply_days"
                        min="1"
                        max="999"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.description"
                          )
                        }}</strong></label
                      >
                      <textarea
                        class="form-control form-control-xs"
                        @keydown="
                          () => {
                            isEditing = true;
                          }
                        "
                        v-model="selectedPrescription.directions"
                        placeholder="Directions of use"
                        :disabled="true"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group text-left">
                      <label
                        ><strong>{{
                          $t(
                            "provider.prescriptionDetails.medications.medicines.pharmacyNotes"
                          )
                        }}</strong></label
                      >
                      <textarea
                        class="form-control form-control-xs"
                        @keydown="
                          () => {
                            isEditing = true;
                          }
                        "
                        v-model="selectedPrescription.pharmacy_note"
                        placeholder="Notes to Pharmacy"
                        :disabled="true"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prescriptionDetails: Object,
    medicine: Object,
    units: Array,
  },
  watch: {
    prescriptionDetails: function (old, newVal) {
      //   console.log(this.prescriptionDetails);
    },
    medicine: function (old, newVal) {
      this.selectedPrescription =
        this.medicine && this.medicine.old_prescriptions
          ? this.medicine.old_prescriptions[0]
          : null;
    },
  },
  data() {
    return {
      selectedPrescription: null,
    };
  },
  mounted() {
    this.selectedPrescription =
      this.medicine && this.medicine.old_prescriptions
        ? this.medicine.old_prescriptions[0]
        : null;
  },
  methods: {
    showRejectRxChangeModal(dspPrescriptionId, rxChangeId) {
      this.$emit(
        "showRejectRxChangeModal",
        JSON.stringify({
          dspPrescriptionId: dspPrescriptionId,
          selectedRxChangeId: rxChangeId,
        })
      );
    },
    approveRxChangeRequest(
      rxChangeRequestId,
      requestedPrescriptionId,
      originalPrescriptionId
    ) {
      this.$emit(
        "approveRxChangeRequest",
        JSON.stringify({
          rxChangeRequestId,
          requestedPrescriptionId,
          originalPrescriptionId,
        })
      );
    },
    selectPrescription(rxChange) {
      this.selectedPrescription = rxChange;
    },
    getRxChangeStatusColor(status) {
      switch (status) {
        case "Pending":
          return "badge badge-pill bg-warning";

        case "Completed":
          return "badge badge-pill bg-success";

        case "Denied":
          return "badge badge-pill bg-danger";

        case "Obsolete":
          return "badge badge-pill bg-secondary";

        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
