<template>
  <div>
    <div class="main-wrapper">
      <layout-header></layout-header>

      <!-- Breadcrumb -->
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-prescriptions-center">
            <div class="col-md-12 col-12">
              <h2 class="breadcrumb-title">Rx Refills & Requests</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- /Breadcrumb -->

      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div class="stickyside">
                <sidebar1></sidebar1>
              </div>
            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header row">
                      <div class="col-6">
                        <h4 class="card-title">
                          {{ $t("patient.rxRefillAndRequests.title") }}
                        </h4>
                      </div>


                      <div class=" mt-2 col-12 col-md-6">
                        <router-link to="/patient/new-prescription">
                          <button class="btn btn-info btn-sm btn-block mb-2">
                            <i class="fas fa-file-prescription mr-2" />
                            {{ $t("patient.rxRefillAndRequests.button2") }}
                          </button>
                        </router-link>
                        <router-link to="/patient/new-refill">
                          <button class="btn btn-info btn-sm btn-block">
                            <i class="fas fa-file-signature mr-2" />
                            {{ $t("patient.rxRefillAndRequests.button1") }}
                          </button>
                        </router-link>
                      </div>


                      <!-- <div class="col-6">
                        <router-link to="/patient/new-prescription">
                          <button class="btn btn-info btn-sm float-right">
                            <i class="fas fa-file-prescription mr-2" />
                            {{ $t("patient.rxRefillAndRequests.button2") }}
                          </button>
                        </router-link>
                        <router-link to="/patient/new-refill">
                          <button class="btn btn-info btn-sm float-right mr-2">
                            <i class="fas fa-file-signature mr-2" />
                            {{ $t("patient.rxRefillAndRequests.button1") }}
                          </button>
                        </router-link>
                      </div> -->
                    </div>

                    <!-- web view table -->
                    <div class="card-body webViewTable">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    {{
                                      $t("patient.rxRefillAndRequests.head1")
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t("patient.rxRefillAndRequests.head2")
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t("patient.rxRefillAndRequests.head3")
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t("patient.rxRefillAndRequests.head4")
                                    }}
                                  </th>
                                  <th>
                                    {{
                                      $t("patient.rxRefillAndRequests.head5")
                                    }}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(prescription, i) in prescriptions" :key="i">
                                  <td>
                                    <strong>{{ prescription.id }}</strong>
                                  </td>
                                  <td>
                                    {{
                                      new Date(
                                        prescription.created_at
                                      ).toDateString()
                                    }}
                                  </td>
                                  <td>
                                    <span v-if="prescription.type === 'refill'">
                                      {{ normalizeString("Refill") }}
                                    </span>
                                    <span v-else>
                                      {{ normalizeString("Free Text") }}
                                    </span>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar" v-if="
                                      (prescription.provider &&
                                        prescription.status ===
                                        'In Progress') ||
                                      prescription.status === 'Accepted' ||
                                      prescription.status === 'Completed' ||
                                      prescription.status === 'Cancelled'
                                    ">
                                      <a v-if="prescription.provider" href="javascript:void();"
                                        class="avatar avatar-sm mr-2">
                                        <img class="avatar-img rounded-circle" :src="prescription.provider
                                            .profile_image_url
                                          " alt="User Image" />
                                      </a>
                                      <a href="javascript:void();" v-if="prescription.provider">{{
                                        prescription.provider.first_name }}
                                        {{ prescription.provider.last_name }}
                                        <span>{{
                                          prescription.provider.description
                                        }}</span>
                                      </a>
                                    </h2>
                                    <h2 class="table-avatar" v-else>
                                      <a href="javascript:void();">Not Assigned Yet
                                        <span>Waiting for providers...</span>
                                      </a>
                                    </h2>
                                  </td>
                                  <td>
                                    <a href="javascript:void(0);" :class="getStatusColor(prescription.status)
                                      ">
                                      {{ prescription.status }}
                                    </a>
                                  </td>
                                  <td class="text-right">
                                    <div class="table-action">
                                      <a v-if="
                                        !prescription.is_payment_completed &&
                                        prescription.status !== 'Cancelled'
                                      " href="javascript:void();" @click="
                                          $router.push(
                                            `/patient/billing/${prescription.id}`
                                          )
                                          " class="btn btn-sm bg-primary-light mr-2 btn-block">
                                        Pay Fees
                                      </a>
                                      <a v-else href="#prescription-details-modal" data-toggle="modal"
                                        class="btn btn-sm bg-info-light" @click="
                                          setPrescriptionDetails(prescription)
                                          ">
                                        <i class="far fa-eye"></i> View Details
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Mobile View -->
            <div class="mobileView" v-for="(prescription, i) in prescriptions" :key="i">
              <div class="card shadow-sm bg-white rounded mb-3" style="min-width: 24rem">
                <div class="card-header bg-transparent row">
                  <div class="col-8">
                    Presc. No. :
                    <strong>#{{ prescription.prescriptionNumber }}</strong>
                  </div>
                  <div class="col-4">
                    <a href="#prescription-details-modal" data-toggle="modal" class="btn btn-sm bg-info-light"
                      @click="setPrescriptionDetails(prescription)">
                      <i class="far fa-eye"></i> View
                    </a>
                  </div>
                </div>
                <div class="card-body text-secondary">
                  <p>
                    Date: {{ new Date(prescription.createdAt).toDateString() }}
                  </p>
                  <p>
                    Provider:
                    <span v-if="
                      prescription.status !== 'Assigned' &&
                      prescription.status !== 'Completed'
                    ">Not Assigned</span>
                    <span v-else>{{ prescription.created_by }}</span>
                  </p>
                  <p>
                    Status:
                    <a href="javascript:void(0);" :class="getStatusColor(prescription.status)">
                      {{ prescription.status }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <!-- Mobile View close -->
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <layout-footer></layout-footer>
    </div>

    <!-- Prescription Details Modal -->
    <div class="modal fade" id="prescription-details-modal" style="display: none">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-body">
        <PrescriptionDetails :prescription="selectedPrescription" />
      </div>
      <!-- Close button at the bottom-right -->
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        style="position: absolute; top: 10px; right: 0px; z-index: 1050; background: transparent; border: none; font-size: 30px; color:red;"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

  </div>
</template>
<script>
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";
import { mapActions } from "vuex";
import PrescriptionDetails from "./PrescriptionDetails.vue";

const images = require.context(
  "../../assets/img/doctors",
  false,
  /\.png$|\.jpg$/
);

export default {
  data() {
    return {
      prescriptions: [],
      selectedPharmacy: {},
      selectedMedicines: {},
      patientInformation: {},
      healthInformation: {},
      prescriptionMetaInfo: {},
      selectedPrescription: {},
    };
  },
  components: {
    PrescriptionDetails: PrescriptionDetails,
  },
  async mounted() {
    this.getPrescriptions();
  },
  methods: {
    ...mapActions(["showLoader"]),
    normalizeString(str) {
      return (
        String(str).charAt(0).toUpperCase() + String(str).slice(1).toLowerCase()
      );
    },
    setPrescriptionDetails(prescriptionDetails) {
      this.selectedPrescription = prescriptionDetails;
    },
    getStatusColor(status) {
      switch (status) {
        case "Pending":
          return "btn btn-sm bg-warning-light mr-1";

        case "Assigned":
          return "btn btn-sm bg-primary-light mr-1";

        case "Completed":
          return "btn btn-sm bg-success-light mr-1";

        case "Accepted":
          return "btn btn-sm bg-primary-light mr-1";

        case "Cancelled":
          return "btn btn-sm bg-danger-light mr-1";

        default:
          return "btn btn-sm bg-primary-light mr-1";
      }
    },
    async getPrescriptions() {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL + HTTP_API.patientGetPrescriptions}/${this.$store.getters.user.id
          }`
        );
        this.prescriptions = res.data;
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
      }
    },
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .webViewTable {
    display: none;
  }

  .mobileView {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
  }
}

@media only screen and (min-width: 600px) {
  .mobileView {
    display: none;
  }
}
</style>
